import React from "react";
import SEO from "../components/seo";
import AboveFold from "../components/aboveFold";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import Review from "../components/review";
import StyledBackgroundSection from "../components/backgroundCarpentry";
import SectionHeading from "../components/sectionHeading";
import { 
  Button, 
  Container, 
  Row, 
  Col,
} from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const Siding = () => {
    const data = useStaticQuery(graphql`
    query {
      wideDivider1: file(relativePath: { eq: "new2.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      img1: file(relativePath: { eq: "3seasonB1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      img2: file(relativePath: { eq: "3seasonB2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      img3: file(relativePath: { eq: "3seasonB3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      img4: file(relativePath: { eq: "3season2.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      
      paragraph1: file(relativePath: { eq: "new1.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      paragraph2: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  
 return (
  <Layout>
    <SEO title="New Construction Contractor" path='new-construction-contractor' />
    <StyledBackgroundSection/>
    <AboveFold
      title='New Construction'
      >
    </AboveFold>
    
    <Hr thick/>
    
    <main id='main'>
      <div id='construction' className='cg-sectionShaded'>
        <div className='cg-section' style={{ }}>
          <Container fluid>
            <Row>
              <Col>
                <p>
                  No matter what you need built, we can help you get the job done. Take a look at our work, then <a href='#contactForm' className='cg-linkDark'>contact&nbsp;us</a> for a&nbsp;quote&nbsp;today!
                </p>              
              </Col>
            </Row>
            <Row>
              <Col md>
                <div style={{padding:'1rem', textAlign: 'center'}}>
                  <Img fluid={data.img1.childImageSharp.fluid} className="cg-picture" alt="construction photo"/>
                </div>
                <p className='cg-caption'>We transform this into a beautiful three-season&nbsp;room...</p>
              </Col>
              <Col md>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.img2.childImageSharp.fluid} className="cg-picture" alt="construction photo"/>
                </div>
                <p className='cg-caption'>We've started...</p>
              </Col>
            </Row>
            <Row>
              <Col md>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.img3.childImageSharp.fluid} className="cg-picture" alt="construction photo"/>
                </div>
                <p className='cg-caption'>It's coming along...</p>
              </Col>
              <Col md>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.img4.childImageSharp.fluid} className="cg-picture" alt="construction photo"/>
                </div>
                <p className='cg-caption'>Looks great!</p>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <Link className='btn btn-secondary' to='/portfolio-construction'>More Photos</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr/>
      
      <div id='guide' className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col md>
                <div style={{padding:'1rem', textAlign:'center'}}>
                  <Review title='Highly recommended!' name='Frank and Sabrina G'>
                    Mike built our garage for us. The process in its entirety was stress free because of his attentiveness to our wants as well as the details of the job itself. He is very fair priced and extremely reliable. It was well appreciated to have a contractor that communicated well with every aspect of the job. He is a rarity in his craft and will definitely be doing business with him again. Highly recommended!
                  </Review>
                </div>
              </Col>
              <Col md>
                <div style={{padding:'1rem', textAlign:'center'}}>
                  <Review title='Outstanding!' name='Mike J.' thumbtack>
                    Gets the job done fast and does an outstanding job!
                  </Review>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr/>
      
      <div id='paragraph' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>About MLB&nbsp;Contracting</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-block d-sm-none cg-mini-picture-box'>
                  <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture'/>
                 </div>
                <p>
                  At MLB Contracting we want you to feel good about your house. 
                  We have a <Link to="/reviews#main" className='cg-linkDark'>track record</Link> that 
                  proves that you can get any structure built well, on time and on budget. It's easy: 
                  1.Get a quote 2.We'll contact you to discuss project details 3.We'll 
                  schedule a start date that works for you. So,  <a href="#contactForm" className='cg-linkDark'>request a quote</a> today. 
                  So you can stop just thinking about your project, and instead get it&nbsp;done!
                </p>
              </Col>
              <Col sm={4} md={5} lg={6} xl={7} className='d-none d-sm-block'>
                {/*<Img fluid={data.paragraph2.childImageSharp.fluid} className='cg-picture'  style={{maxWidth:200}}/>*/}
                <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture' style={{maxWidth:200, marginTop:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <Button variant='secondary' href='/reviews#main'>Reviews</Button>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider1.childImageSharp.fluid}/>
      
    </main>
    
    <ContactForm/> 
    
        
    <div id='footnotes' className='cg-sectionLight'>
      <div className='cg-section'>
        <Container fluid>
          <Row>
            <Col style={{ textAlign:'center', color:'#aaa'}}>
              <small>All photos are from MLB Contracting projects.</small>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    
  </Layout>
)};


export default Siding;
